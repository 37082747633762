<template>
  <div class="p-onlineClinicExaminationShow">
    <loading :translucent="true" v-show="loading"></loading>
    <flash-message/>

    <div v-if="!isFinished" class="p-onlineClinicExaminationShow__primary">
      <h3 class="p-onlineClinicExaminationShow__title">予約確認</h3>
      <p class="p-onlineClinicExaminationShow__subtitle">予約時間までに事前準備・利用方法をお読みください</p>

      <a class="p-onlineClinicExaminationShow__advance" href="#onlineClinic-guidemodel" @click="$mixpanel.track('CLICK BUTTON', { page: '予約詳細', buttonName: '事前準備・利用方法', userId: currentUser.id, source: currentUser.source })">事前準備・利用方法</a>

      <button class="c-mainButton p-onlineClinicExaminationShow__buttonSize" @click="go_to_web_mtg(); $mixpanel.track('CLICK BUTTON', { page: '予約詳細', buttonName: '診察をはじめる', userId: currentUser.id, source: currentUser.source })" :disabled="!examinable">診察をはじめる
      </button>
      <router-link v-if="examination && !examination.billing_amount  && !(isChineseMedicineInterview && isStart)" class="c-mainButton p-onlineClinicExaminationShow__buttonSize" :to="`/online_clinic/examinations/${$route.params.id}/edit`" @click.native="$mixpanel.track('CLICK BUTTON', { page: '予約詳細', buttonName: '問診内容を修正する', userId: currentUser.id, source: currentUser.source })"> 問診内容を修正する</router-link>
      <p class="p-onlineClinicExaminationShow__supplement">※予約5分前になると入れます</p>

      <a class="p-onlineClinicExaminationShow__cancel" href="#onlineClinic-cancelmodel" @click="$mixpanel.track('CLICK BUTTON', { page: '予約詳細', buttonName: '予約キャンセル', userId: currentUser.id, source: currentUser.source })">予約キャンセル</a>
    </div>

    <div v-if="isFinished" class="p-onlineClinicExaminationShow__primary">
      <p class="p-onlineClinicExaminationShow__message">診察お疲れさまでした。</p>
      <p class="p-onlineClinicExaminationShow__message">トップへ戻り処方せんの発行をお待ちください。</p>
      <p class="onlineClinic-afterclinicreservation__date">※診察を受けないまま予約時間を過ぎてしまった場合は、<a href="/contacts/new">こちら</a>からお問い合わせください。
      </p>

      <div class="p-onlineClinicExaminationShow__footer">
        <router-link v-if="examination && !examination.billing_amount && !isChineseMedicineInterview" class="c-mainButton p-onlineClinicExaminationShow__buttonSize" :to="`/online_clinic/examinations/${$route.params.id}/edit`" @click.native="$mixpanel.track('CLICK BUTTON', { page: '予約詳細', buttonName: '問診内容を修正する', userId: currentUser.id, source: currentUser.source })">問診内容を修正する</router-link>
        <router-link class="c-mainButton p-onlineClinicExaminationShow__buttonSize" to="/online_clinic" @click.native="$mixpanel.track('CLICK BUTTON', { page: '予約詳細', buttonName: 'トップに戻る', userId: currentUser.id, source: currentUser.source })">トップに戻る</router-link>
      </div>
    </div>


    <div class="p-onlineClinicExaminationShow__container l-ignoreParentContainer">
      <div class="p-onlineClinicExaminationShow__form">
        <span class="p-onlineClinicExaminationShow__label">診療機関</span>
        <p class="p-onlineClinicExaminationShow__item">{{ clinicName }}</p>
      </div>

      <div class="p-onlineClinicExaminationShow__form">
        <span class="p-onlineClinicExaminationShow__label">薬局名</span>
        <p class="p-onlineClinicExaminationShow__item">{{ pharmacyName }}</p>
      </div>

      <div class="p-onlineClinicExaminationShow__form">
        <span class="p-onlineClinicExaminationShow__label">診療科目</span>
        <p class="p-onlineClinicExaminationShow__item">{{ medicalDepartmentName }}</p>
      </div>

      <div class="p-onlineClinicExaminationShow__form">
        <span class="p-onlineClinicExaminationShow__label">予約日時</span>
        <p class="p-onlineClinicExaminationShow__item">{{ timeSchedule }}</p>
      </div>

      <div class="p-onlineClinicExaminationShow__form">
        <span class="p-onlineClinicExaminationShow__label">担当医師</span>
        <div class="p-onlineClinicExaminationShow__img">
          <img :src="doctorImage">
        </div>
        <p class="p-onlineClinicExaminationShow__name">{{ doctorName }}</p>
        <p class="p-onlineClinicExaminationShow__profile">{{ doctorIntroduction }}</p>
      </div>

      <div class="p-onlineClinicExaminationShow__form">
        <span class="p-onlineClinicExaminationShow__label">来院される方</span>
        <p class="p-onlineClinicExaminationShow__item">{{ patientName }}</p>
      </div>

      <component :is="currentComponent"
                 ref="currentComponent"
                 :clinicReservationToCreate="examination"
                />

    </div>

    <div class="p-onlineClinicExaminationShow__footer">
      <router-link class="c-mainButton p-onlineClinicExaminationShow__buttonSize" to="/online_clinic" @click.native="$mixpanel.track('CLICK BUTTON', { page: '予約詳細', buttonName: 'トップに戻る', userId: currentUser.id, source: currentUser.source })">トップに戻る</router-link>
    </div>

    <!--       ガイドモーダル -->

    <div class="onlineClinic-guidemodel" id="onlineClinic-guidemodel">
      <a href="#" class="onlineClinic-guidemodel__overlay"></a>
      <div class="onlineClinic-guidemodel__window">
        <div class="onlineClinic-guidemodel__content">
          <p class="onlineClinic-guidemodel__title">事前準備・利用方法</p>

          <ol class="onlineClinic-guidemodel__list">
            <li class="onlineClinic-guidemodel__item">
              <p>予約時間になりましたら、「診察をはじめる」ボタンをタップします</p>
            </li>
            <li class="onlineClinic-guidemodel__item">
              <p>お名前を本名で入力します</p>
            </li>
            <li class="onlineClinic-guidemodel__item">
              <p>マイクとカメラのアクセスを許可します</p>
            </li>
            <li class="onlineClinic-guidemodel__item">
              <p>はじめに本人確認を行いますので、保険証をご提示ください</p>
              <p>※ビデオ通話は外部サービスWherebyを使って行います</p>
              <a href="https://whereby.com/">https://whereby.com/</a>
            </li>
          </ol>
        </div>
        <a href="#" class="onlineClinic-guidemodel__close">×</a>
      </div>
    </div>


    <!--       ガイドモーダル -->
    <!--       キャンセルモーダル -->
    <div class="onlineClinic-cancelmodel" id="onlineClinic-cancelmodel">
      <a href="#" class="onlineClinic-cancelmodel__overlay"></a>
      <div class="onlineClinic-cancelmodel__window">
        <div class="onlineClinic-cancelmodel__content">
          <p class="onlineClinic-cancelmodel__title">予約キャンセル</p>
        </div>

        <div class="onlineClinic-cancelmodel__body">
          <p>診療をキャンセルしますか？</p>
          <span>一度キャンセルすると再度予約の取り直しになります。</span>

          <a @click="cancelExamination()" class="onlineClinic-cancelmodel__button">
            診療予約のキャンセルを確定する
          </a>
          <a href="#" class="onlineClinic-cancelmodel__cancel">
            診療を予約したまま確認画面へ戻る
          </a>
        </div>

      </div>
    </div>
    <!--       キャンセルモーダル -->
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from "moment";
  import {ja} from 'vuejs-datepicker/dist/locale'
  import VueLocalStorage from 'vue-localstorage'
  import FlashMessage from '@/components/online_clinic/FlashMessage'
  import InterviewFormComponent from '@/enums/online_clinic/InterviewFormComponent';
  import DefaultCompleted from '@/components/online_clinic/clinics/reservations/reservationCompleted/Default';
  import ChineseMedicineCompleted from '@/components/online_clinic/clinics/reservations/reservationCompleted/ChineseMedicine';
  import EmergencyContraceptionCompleted from '@/components/online_clinic/clinics/reservations/reservationCompleted/EmergencyContraception';
  import PmsCompleted from '@/components/online_clinic/clinics/reservations/reservationCompleted/Pms';
  import Loading from '@/components/Common/Loading';
  import {mapGetters} from "vuex";


  export default {
    name: 'Examination',
    data: () => {
      return {
        examination: null,
        currentDatetime: moment(),
        loading: false
      }
    },
    components: {
      FlashMessage,
      DefaultCompleted,
      ChineseMedicineCompleted,
      EmergencyContraceptionCompleted,
      PmsCompleted,
      Loading
    },
    filters: {
      convertGenericValueToText(value) {
        switch(value){
          case 'yes':
            return 'はい'
            break;
          case 'no':
            return 'いいえ'
            break;
        }
      },
    },
    computed: {
      currentComponent() {
        if (this.examination != null){
          return InterviewFormComponent.name[this.examination.interview_type.interview_table_name] + 'Completed'
        }
      },
      clinicName() {
        return this.examination == null ? '読込中...' : this.examination.clinic.name
      },
      pharmacyName() {
        if (this.examination == null) {
          return '読込中...';
        } else if (this.examination.pharmacy == null) {
          return '利用しない';
        } else {
          return this.examination.pharmacy.name
        }
      },
      medicalDepartmentName() {
        return this.examination == null ? '読込中...' : this.examination.medical_department.name
      },
      timeSchedule() {
        if (this.examination == null) {
          return '読込中...'
        } else {
          moment.locale('ja', {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
          let startTime = moment(this.examination.doctor_schedule.start).format('HH:mm');
          let endTime = moment(this.examination.doctor_schedule.end).format('HH:mm');
          let day = moment(this.examination.doctor_schedule.end).format('YYYY/MM/DD(ddd)');
          return `${day} ${startTime} ~ ${endTime}`
        }

      },
      doctorImage() {
        return this.examination == null ? '読込中...' : this.examination.doctor.doctor_profile.image.url
      },
      doctorName() {
        return this.examination == null ? '読込中...' : `${this.examination.doctor.doctor_profile.first_name}${this.examination.doctor.doctor_profile.last_name} 先生`
      },
      doctorIntroduction() {
        return this.examination == null ? '読込中...' : `${this.examination.doctor.doctor_profile.self_introduction}`
      },
      patientName() {
        return this.examination == null ? '読込中...' : `${this.examination.interview_for_department.patient_first_name}${this.examination.interview_for_department.patient_last_name}`
      },
      examinable() {
        let start = this.examination == null ? null : moment(this.examination.doctor_schedule.start)
        let end = this.examination == null ? null : moment(this.examination.doctor_schedule.end)
        if (start == null) {
          return false;
        } else if (start.diff(this.currentDatetime, 'minutes') < 5 && end > this.currentDatetime) {
          return true;
        } else {
          return false;
        }
      },
      isFinished() {
        if (this.examination == null) {
          return false;
        } else {
          let end = moment(this.examination.doctor_schedule.end)
          return end < this.currentDatetime
        }
      },
      isChineseMedicineInterview() {
        return this.examination.interview_type.interview_table_name == 'chinese_medicine_interview' ? true : false
      },
      isStart() {
        return moment(this.examination.doctor_schedule.start) < moment()
      },
      ...mapGetters(['currentUser'])
    },
    async created() {
			window.scroll(0, 0)
      try {
        await this.fetchExamination();
      } catch {
        alert("ERROR")
      }
    },
    mounted() {
      setInterval(() => {
        this.currentDatetime = moment();
      }, 5000)
    },
    methods: {
      async cancelExamination() {
        window.location.href = '#'
        this.loading = true
        try {
          await axios.delete(`/api/online_clinic/examinations/${this.$route.params.id}`);
          this.$localStorage.set('flashType', 'info')
          this.$localStorage.set('flashMessage', '診察予約をキャンセルしました')
          window.location.href = '/online_clinic'
        } catch {
          alert("ERROR")
        } finally {
          this.loading = false
        }
      },
      async fetchExamination() {
        try {
          let examination = await axios.get(`/api/online_clinic/examinations/${this.$route.params.id}`);
          this.examination = examination.data

        } catch {
          alert("ERROR")
        }
      },
      go_to_web_mtg() {
        this.$router.push(`/online_clinic/web_mtg/${this.examination.id}`)
      },
    },

  }

  // ビルドエラーになりレンダリングされなくなるので一旦コメントアウト
  // $(function () {
  //   var scrollPosition;
  //   $(".p-onlineClinicExaminationShow__advance").on("click", function () {
  //     scrollPosition = $(window).scrollTop();
  //     $('.onlineClinic-advancepreparation').addClass('fixed').css({'top': -scrollPosition});
  //   });
  //   $(".onlineClinic-guidemodel__close").on("click", function () {
  //     $('.onlineClinic-advancepreparation').removeClass('fixed').css({'top': 0});
  //     window.scrollTo(0, scrollPosition);
  //   });
  // });
</script>
