<template>
  <div>
    <div class="text-center">
      <img class="p-HealthCheckupSupportHome__mainImage" src="@/../assets/images/health_checkup_support/home/main.png" alt="">
    </div>
    <div class="p-HealthCheckupSupportHome__titleBox">
      <p class="p-HealthCheckupSupportHome__mainText">健康診断の結果を元にした、<br>健康的なライフスタイルのサポート</p>
    </div>
    <div class="p-HealthCheckupSupportHome__info">
      <p class="p-HealthCheckupSupportHome__status">
          現在のステータス
      </p>
      <p class="p-HealthCheckupSupportHome__msg">
          予約時間になったらビデオ通話を開始しましょう
      </p>
      <span class="p-HealthCheckupSupportHome__tag">予約日時</span>
      {{timeSchedule}}
      
    </div>
    <a @click="goDetail(); $mixpanel.track('CLICK BUTTON', { page: '健診サポートトップ', buttonName: '健診サポートを受ける', userId: currentUser.id, source: currentUser.source, isCvButton: true })" class="c-mainButton" :class="{ disabled: !isCompletedLoading }">健診サポートを受ける</a>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="consultation && consultation.chat ? consultation.chat.id : null"
    ></notification-button>


    <a href="/health_checkup_support/consultation_histories" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '健診サポートトップ', buttonName: '過去の健診サポートを確認', userId: currentUser.id, source: currentUser.source })">
      過去の健診サポートを確認
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '健診サポートトップ', buttonName: '会員情報を確認', userId: currentUser.id, source: currentUser.source })">
      会員情報を確認
    </a>
  </div>
</template>


<script>
import NotificationButton from "@/components/health_checkup_support/home/NotificationButton";
import { mapGetters, mapActions } from 'vuex';
import * as PatientFamilyProfile from  'store/modules/patientFamilyProfiles';
import moment from 'moment';

export default {
  name: 'BeforeClinicReservation',
  props: ['consultation'],
  components: {
    NotificationButton
  },
  async created() {
    this.fetchPatientFamilyProfiles()
  },
  props: ['consultation'],
  computed: {
    currentUrl() {
      return location.href
    },
    isCompletedLoading() {
      return this.patientFamilyProfiles != 'LOADING_QUERY'
    },
    timeSchedule() {
        if (this.consultation == null) {
          return ''
        } else {

          moment.locale('ja', {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
          const startTime = moment(this.consultation.web_mtg_start).format('HH:mm');
          const endTime = moment(this.consultation.web_mtg_end).format('HH:mm');
          const day = moment(this.consultation.web_mtg_start).format('YYYY/MM/DD(ddd)');

          return `${day} ${startTime} ~ ${endTime}`
        }
      },
    ...mapGetters(['currentUser']),
    ...mapGetters({patientFamilyProfiles: 'patientFamilyProfiles/query'})
  },
  methods: {
    goDetail() {
      if (this.isCompletedLoading) {
        this.$router.push(`/health_checkup_support/consultations/${this.consultation.id}`);
      }
    },
    ...mapActions({ fetchPatientFamilyProfiles: `patientFamilyProfiles/${PatientFamilyProfile.actionTypes.FETCH_QUERY}` })
  },
};
</script>