<template>
  <a :href="href" class="p-onlineClinicHome__notificationButton" v-bind:class="{ disabled: !hasEmergencyConsultation }" :disabled="!hasEmergencyConsultation" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '連絡事項があります', userId: currentUser.id, userId: currentUser.id, source: currentUser.source })">
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="7.50001" cy="5.55599" rx="7.50001" ry="5.55599" :fill="iconColor"/>
      <path d="M13.5671 13.0005L7.39062 6.97852H12.5377L13.5671 13.0005Z" :fill="iconColor"/>
    </svg>
    連絡事項があります
  </a>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    props: {
      hasEmergencyConsultation: Boolean,
      chatId: {
        type: Number,
        required: false,
        default: NaN
      }
    },

    computed: {
      iconColor() {
        if (this.hasEmergencyConsultation) {
          return "#04AAE4"
        } else {
          return "#fff"
        }
      },
      href() {
        if (this.hasEmergencyConsultation) {
          return `/chat/${this.chatId}?back_to=online_clinic`
        } else {
          return '#'
        }
      },
      ...mapGetters(['currentUser'])
    },
  }
</script>
