<template>
  <span v-if="isShow" class="chat-log-content">
    <a :href="hrefUrl.href" :target="hrefUrl.target">
      <img :src="chatLog.image"/>
    </a>
  </span>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import * as actionTypes from '../../store/action-types'

  const urlWithDeviceOption = function(url) {
    if (isIOS() && !isSafari()) {
      return { href:"anamne-app-settings://anamne-open-web?" + url};
    } else {
      return { href: url, target: '_blank' };
    }
    function isIOS () {
      return /[ \(]iP/.test(navigator.userAgent)
    }

    function isSafari() {
      var userAgent = window.navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('msie') != -1 ||
        userAgent.indexOf('trident') != -1 ||
        userAgent.indexOf('edge') != -1 ||
        userAgent.indexOf('chrome') != -1
      ) {
        return false
      } else if (userAgent.indexOf('safari') != -1) {
        return true
      }
    }
  };

  export default {
    name: 'ChatLogImage',
    props: ['chatLog', 'itemType'],
    methods: {
    },
    computed: {
      hrefUrl () {
        return urlWithDeviceOption(this.chatLog.full_image)
      },
      isShow () {
        return this.itemType === ChatLogEnum.ChatItemTypes.IMAGE
      },
      ...mapGetters(['currentUser', 'chat'])
    }
  }
</script>

<style scoped lang="scss">
  span {
    display: block;
  }

  .chat-log-content img {
    max-width:100%;
  }
</style>
