<template>
  <div class="c-mainModal">
    <div class="c-mainModal__box">
      <div class="c-mainModal__header p-interviewClinicSelectionModal__title">
        診療機関の選択とは
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>
      <div class="c-mainModal__container">
        <div class="p-interviewClinicSelectionModal__container">
          <div class="p-interviewClinicSelectionModal__text">
            <div class="p-interviewClinicSelectionModal__main-text">
              選択する診療機関により、処方せんの発行時間が異なります。診療機関の営業時間中に処方せんを発行します。予約時間によっては、処方せん発行までお時間をいただく場合がございます。
            </div>
            <div class="p-interviewClinicSelectionModal__sub-text">
              <p class="p-interviewClinicSelectionModal__sub-text--title">※助成資格をお持ちの方へ</p>
              <p class="p-interviewClinicSelectionModal__sub-text--content">診療機関とお住まいの都道府県が同じ場合は、お会計時に保険診療費の助成が適用されます。異なる場合は、後日、お住まいの自治体に申請することで助成を受けることができます。</p>
            </div>
          </div>
          <div class="p-interviewClinicSelectionModal__clinics">
            <div class="p-interviewClinicSelectionModal__clinic">
              <div class="p-interviewClinicSelectionModal__clinic--name">ヒロオカクリニック</div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">営業時間</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">09:00-12:30 / 14:00-17:30</div>
              </div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">休診日</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">日、祝日 <span class="p-interviewClinicSelectionModal__clinic--information--sub-content">※土:12:00まで</span></div>
              </div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">所在地</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">東京都新宿区新宿2-5-12</div>
              </div>
            </div>
            <div class="p-interviewClinicSelectionModal__clinic">
              <div class="p-interviewClinicSelectionModal__clinic--name">東京メディカルクリニック 平和台駅前院</div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">営業時間</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">08:45-11:30 / 14:15-18:30</div>
              </div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">休診日</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">水、日、祝日 <span class="p-interviewClinicSelectionModal__clinic--information--sub-content">※土:11:30まで</span></div>
              </div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">所在地</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">東京都練馬区早宮2-17-47</div>
              </div>
            </div>
            <div class="p-interviewClinicSelectionModal__clinic">
              <div class="p-interviewClinicSelectionModal__clinic--name">福永記念診療所</div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">営業時間</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">09:00-12:00 / 13:30-19:30</div>
              </div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">休診日</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">日、祝日 <span class="p-interviewClinicSelectionModal__clinic--information--sub-content">※水・土:12:00まで</span></div>
              </div>
              <div class="p-interviewClinicSelectionModal__clinic--information">
                <div class="p-interviewClinicSelectionModal__clinic--information--title">所在地</div>
                <div class="p-interviewClinicSelectionModal__clinic--information--content">大阪府大阪市城東区中央1-9-33</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-mainModal__overlay">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
  },
};
</script>
