<template>
  <div>
    <div class="text-center">
      <img class="p-onlineClinicHome__mainImage" src="../../../../../assets/images/online_clinic/main.png" alt="">
    </div>

    <div class="p-onlineClinicHome__info">
      <p class="p-onlineClinicHome__caption">オンライン診療にかかる料金</p>
      <div class="p-onlineClinicHome__borderBody">
        <div class="p-onlineClinicHome__border" style="border: solid;">
          <div class="p-onlineClinicHome__borderComment">
            通常の医療費
          </div>
          <div class="p-onlineClinicHome__borderSubcomment">
            保険診療費<sup>※1</sup>
          </div>
        </div>
        <div class="p-onlineClinicHome__plus">+</div>
        <div class="p-onlineClinicHome__border" style="border: solid;">
          <div class="p-onlineClinicHome__borderSubcomment">
            手数料
          </div>
          <div class="p-onlineClinicHome__borderComment">
            <div class="p-onlineClinicHome__textContainer">
              1,100<span class="currency">円</span><span class="supplement">※2</span>
            </div>
          </div>
        </div>

      </div>
      <p class="p-onlineClinicHome__subComment">+ 決済手数料（保険診療費+オンライン診療手数料）×4.5%</p>
    </div>
    <p class="p-onlineClinicHome__annotation">※1 一部自費診療費</p>
    <p class="p-onlineClinicHome__annotation">※2 2024/7/26の決済より、手数料が1,100円に変更となります。</p>
    <a @click="startOnlineClinic(); $mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '診察を予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })" class="c-mainButton" :class="{ disabled: !isCompletedLoading }">診察を予約する</a>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="this.$props.chat == null ? null : this.$props.chat.id"
    ></notification-button>

    <a href="/online_clinic/examinations" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '過去の診療', userId: currentUser.id, source: currentUser.source })">
      過去の診察
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '会員情報', userId: currentUser.id, source: currentUser.source })">
      会員情報
    </a>
  </div>
</template>


<script>
import NotificationButton from "./buttons/NotificationButton";
import { mapGetters, mapActions } from 'vuex';
import * as PatientFamilyProfile from  'store/modules/patientFamilyProfiles';
export default {
  name: 'BeforeClinicReservation',
  props: ['consultation'],
  components: {
    NotificationButton
  },
  async created() {
    this.fetchPatientFamilyProfiles()
  },
  computed: {
    currentUrl() {
      return location.href
    },
    isCompletedLoading() {
      return this.patientFamilyProfiles != 'LOADING_QUERY'
    },
    ...mapGetters(['currentUser']),
    ...mapGetters({patientFamilyProfiles: 'patientFamilyProfiles/query'})
  },
  methods: {
    startOnlineClinic() {
      if (this.isCompletedLoading) {
        if (this.patientFamilyProfiles.length > 0) {
          this.$router.push('/online_clinic/clinic/reservation/new')
        } else {
          this.$router.push({ name: 'patient_family_profile_new', params: {isFromOnlineClinicHome: true}})
        }
      }
    },
    ...mapActions({ fetchPatientFamilyProfiles: `patientFamilyProfiles/${PatientFamilyProfile.actionTypes.FETCH_QUERY}` })
  },
};
</script>

