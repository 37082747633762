import { PREFECTURES } from "../const/prefectures";

export const hCollectionToArray = collection => Array.prototype.slice.call(collection);

export const urlWithDeviceOption = function(url) {
  if (isIOS() && !isSafari()) {
    return({ href:"anamne-app-settings://anamne-open-web?" + url});
  } else {
    return({ href: url, target: '_blank' });
  }
  function isIOS () {
    return /[ \(]iP/.test(navigator.userAgent)
  }

  function isSafari() {
    var userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.indexOf('msie') != -1 ||
      userAgent.indexOf('trident') != -1 ||
      userAgent.indexOf('edge') != -1 ||
      userAgent.indexOf('chrome') != -1
    ) {
      return false
    } else if (userAgent.indexOf('safari') != -1) {
      return true
    }
  }

};

export const pageScrollToBottom = () => {
  window.setTimeout(
    () => window.scrollTo(0, 100000),
    1
  )
};

const urlRegex = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

export const textFilterWithHref = (createElement, _) => {
  return function(text) {
    const stringArray = text.split(/( )/g);
    const elements = stringArray.map((string) => {
      if(urlRegex.test(string)) {
        return createElement('a', { domProps: urlWithDeviceOption(string) }, string) ;
      } else {
        return string ;
      }
    });
    return elements
  }
}

export const findPrefecture = (prefectureId) => {
  return PREFECTURES.find(prefecture => prefecture.code == prefectureId)
}
