<template>
  <div>
    <keep-alive>
      <component :is="currentComponent"
                 ref="currentComponent"
                 @moveToNextStep="moveToNextStep"
      />
    </keep-alive>
  </div>
</template>

<script>
  import GuideFirst from "@/components/native-app/guides/PublicGuideFirst";
  import GuideSecond from "@/components/native-app/guides/PublicGuideSecond";
  import GuideThird from "@/components/native-app/guides/PublicGuideThird";
  import GuideForth from "@/components/native-app/guides/PublicGuideForth";
  import GuideFifth from "@/components/native-app/guides/PublicGuideFifth";
  import GuideSixth from "@/components/native-app/guides/PublicGuideSixth";

  export default {
    components: {
      GuideFirst,
      GuideSecond,
      GuideThird,
      GuideForth,
      GuideFifth,
      GuideSixth,
    },
    data() {
      return {
        currentStep: 1,
        guideSteps: ['GuideFirst', 'GuideSecond','GuideThird','GuideForth','GuideFifth', 'GuideSixth']
      }
    },
    computed: {
      currentComponent() {
        return this.guideSteps[this.currentStep - 1]
      },
    },
    methods: {
      moveToNextStep() {
        this.currentStep++
      },
    },

  }
</script>
