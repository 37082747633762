<template>
  <div class="l-footer--serveyLink" v-if="isAfterRegistration">
    <a v-bind:href="href" @click="$mixpanel.track('CLICK BUTTON', { page: page, buttonName: 'アンケートフォーム', userId: currentUser.id, source: currentUser.source })">
      <img src="../../../assets/images/home/chart-icon.png">
      ご意見をお聞かせください
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FooterSurveyLink',
  props: ['page'],
  computed: {
    href() {
      const baseUrl = "https://docs.google.com/forms/d/e/1FAIpQLSeB4LMCK44Jr5bfRvdiIB8AoFL1uqNNbmrDhbv362zqKrnC5g/viewform?usp=pp_url"
      const emailKey = "&entry.1223377731="
      return baseUrl + emailKey + this.currentUser.email
    },
    isAfterRegistration() {
      return this.patientFamilyProfiles.length > 0;
    },
    ...mapGetters({currentUser: 'currentUser', patientFamilyProfiles: 'patientFamilyProfiles/query'})
  },
  methods: {},
};
</script>
